import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, AppPages } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import ListView from '../../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { openPopup } from '../../../store/actions';
import { isDocumentReadOnly, skipDocumenConfirmingActions, useConfirmActions } from '../commonFunctions';

const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;
/**
 * @exports Fees
used in Documents menu and in Service Call tab
 */
function Fees(props) {
    const { dataItem, openPopup, disabled } = props;
    const isServiceCallTabMode = !!dataItem;
    const [staticFilter] = useState(isServiceCallTabMode ? { call_id: dataItem && dataItem.call_id } : { confirmed: false });
    const [detailProps] = useState({
        newTitle: 'text.new_fee',
        editTitle: 'text.fee_detail',
        popupType: PopupTypes.FeeDetail,
        windowKey: 'wndFeeDetail',
        newData: isServiceCallTabMode && { data: { callData: dataItem } }
    });
    const { onConfirm, onReject } = useConfirmActions({ openPopup, type: 'PO', listUpdateEvent });

    const columns = [
        {
            text: 'text.purchase_order_id',
            noSort: 'true',
            field: 'DocNum',
            type: COLUMN_TYPE.TEXT,
        },
        {
            skip: isServiceCallTabMode,
            text: 'text.call_id',
            render: (text, record) => {
                return <wrap>
                    {(record.callData && <Link to={Project.getPageUrl(AppPages.ServiceCallsDetails, record.callData.ID)} style={{ color: "var(--main-color)" }}>
                        <text>{record.SCOrigin}</text>
                    </Link>) || <text>{record.SCOrigin}</text>}
                </wrap>;
            },
            noSort: 'true',
            field: 'SCOrigin',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.date',
            noSort: 'true',
            field: 'DocDate',
            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATE
        },
        {
            text: 'text.total',
            noSort: 'true',
            field: 'DocTotal',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            noSort: 'true',
            field: 'DocStatus',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return (
                    <wrap>
                        <group> <text>{text}</text>
                            {record.IsChangeRequested && <badge l_space="" outline=""><T>text.change_requested</T></badge>}
                        </group>
                    </wrap>
                );
            },
        },
        {
            skip: !isServiceCallTabMode,
            text: 'text.confirmed',
            noSort: 'true',
            field: 'Confirmed',
            type: COLUMN_TYPE.TEXT,
        },
        {
            skip: disabled,
            type: COLUMN_TYPE.ACTION,
            commands: [
                { command: 'edit', skipCommandForRecord: isDocumentReadOnly },
                { command: 'edit', icon: 'eye', key: 'view', skipCommandForRecord: record => !isDocumentReadOnly(record) },
                { command: 'done', skipCommandForRecord: skipDocumenConfirmingActions, onClick: onConfirm, tooltip: 'text.approve' },
                { command: 'close', skipCommandForRecord: skipDocumenConfirmingActions, onClick: onReject, tooltip: 'text.reject' }
            ]

        }
    ].filter(col => !col.skip);

    const readOnly = isServiceCallTabMode && isDocumentReadOnly({ callData: dataItem });

    return (
        <ListView
            {...props}
            className='visible_buttons'
            columnConfig={columns}
            staticFilter={staticFilter}
            apiUrl={ApiUrl.FeeList}
            noPaging
            keyField="DocNum"
            listUpdateEvent={listUpdateEvent}
            detailProps={detailProps}
            isSimple={isServiceCallTabMode}
            skipEdit={disabled}
            skipNewButton={disabled || !isServiceCallTabMode || readOnly}
        />
    );
}


export default connect(null, dispatch => ({ openPopup: props => dispatch(openPopup(props)) }))(Fees);