import React from 'react';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../project/Defines';
import InputCont from '../../../Shared/Form/InputCont';

export default function Remark(props) {
    const { dataItem, disabled, onChangeData } = props;
    const role = useSelector(s => s.role);
    console.log(role, 'role');
    return (
        <div className='section_group'>
            <div className='sections wide'>
                <form>

                    <InputCont
                        label='text.description_ga'
                        inputProps={{
                            disabled: disabled || role === UserRoles.USER,
                            name: 'description',
                            type: 'textarea',
                            value: dataItem.description,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                    <InputCont
                        label='text.description_sat'
                        inputProps={{
                            disabled: disabled || role === UserRoles.CALL_CENTER,
                            name: 'description_sat',
                            type: 'textarea',
                            value: dataItem.description_sat,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                </form>

            </div>
        </div>
    );
}