import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, AppPages } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import ListView from '../../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { openPopup } from '../../../store/actions';
import { hasValidCallData, isDocumentReadOnly, skipDocumenConfirmingActions, useConfirmActions } from '../commonFunctions';

const listUpdateEvent = EVENT_NAME.DPC_LIST_UPDATE;
/**
 * @exports DPC
 */
function DPC(props) {
    const { disabled, dataItem, openPopup } = props;
    const isServiceCallTabMode = !!dataItem;
    const [staticFilter] = useState(isServiceCallTabMode ? { call_id: dataItem && dataItem.call_id } : { confirmed: false });
    const [dpcCount, setDpcCount] = useState(null);

    const [detailProps] = useState({
        newTitle: 'text.create_new_dpc',
        editTitle: 'text.edit_dpc',
        fullScreen: false,
        popupType: PopupTypes.DPCDetail,
        windowKey: 'wndDPCDetails',
        newData: isServiceCallTabMode && { data: { callData: dataItem } }
    });

    const { onConfirm, onReject } = useConfirmActions({ openPopup, listUpdateEvent, type: "RR", docType: "DPC" });

    const columns = [
        {
            text: 'text.request_id',
            noSort: 'true',
            field: 'DocNum',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: isServiceCallTabMode,
            text: 'text.call_id',
            render: (text, record) => {
                return <wrap>
                    {(record.callData && <Link to={Project.getPageUrl(AppPages.ServiceCallsDetails, record.callData.ID)} style={{ color: "var(--main-color)" }}>
                        <text>{record.SCOrigin}</text>
                    </Link>) || <text>{record.SCOrigin}</text>}
                </wrap>;
            },
            noSort: 'true',
            field: 'SCOrigin',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.date',
            noSort: 'true',
            field: 'DocDate',
            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATE
        },
        {
            text: 'text.total',
            noSort: 'true',
            field: 'DocTotal',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            noSort: 'true',
            field: 'DocStatus',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return (
                    <wrap>
                        <group> <text>{text}</text>
                            {record.IsChangeRequested && <badge l_space="" outline=""><T>text.change_requested</T></badge>}
                        </group>
                    </wrap>
                );
            },
        },
        {
            text: 'text.type_of_exchange_doc',
            noSort: 'true',
            field: 'ReturnType',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: !isServiceCallTabMode,
            text: 'text.confirmed',
            noSort: 'true',
            field: 'Confirmed',
            type: COLUMN_TYPE.TEXT
        },
        // hasValidCallData("axax") && 
        {
            type: COLUMN_TYPE.ACTION,
            commands: [
                { command: 'edit', skipCommandForRecord: record => (!hasValidCallData(record) || isDocumentReadOnly(record)) },
                { command: 'edit', icon: 'eye', key: 'view', skipCommandForRecord: record => (!hasValidCallData(record) || !isDocumentReadOnly(record)) },
                { command: 'done', skipCommandForRecord: skipDocumenConfirmingActions, onClick: onConfirm, tooltip: 'text.approve' },
                { command: 'close', skipCommandForRecord: skipDocumenConfirmingActions, onClick: onReject, tooltip: 'text.reject' }
            ]

        }
    ].filter(col => !col.skip);

    //   const allowEdit = dataItem && dataItem.is_saved_status_open && dataItem.societe_uuid && dataItem.accepted_at;
    const allowNew = isServiceCallTabMode && !isDocumentReadOnly({ callData: dataItem }) && dpcCount === 0;
    return (
        <ListView
            {...props}
            className='visible_buttons'
            columnConfig={columns}
            keyField='DocNum'
            staticFilter={staticFilter}
            apiUrl={ApiUrl.DPCList}
            noPaging
            listUpdateEvent={listUpdateEvent}
            detailProps={detailProps}
            isSimple
            onLoaded={isServiceCallTabMode ? data => {
                data && setDpcCount(data.length);
                return data;
            } : null}
            onDoubleClick={(record, rowIndex, event, onEdit) => { !disabled && hasValidCallData(record) && !isDocumentReadOnly(record) && onEdit(record, rowIndex, event); }}
            skipNewButton={disabled || !allowNew}
        />
    );
}

export default connect(null, dispatch => ({ openPopup: props => dispatch(openPopup(props)) }))(DPC);
