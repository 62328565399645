import { Select, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Ajax } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';
import { useCustomEvent } from '../../project/utilities';
import { useSWCache } from '../../Shared/SWCache';

const { Option, OptGroup } = Select;

/**
 * specific case of DropDownValueList with 'multiple' mode
 * @param {any} props valuelist, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function MultiSelectValueList(props) {
  return <DropDownValueList {...props} mode='multiple' />;
}

/**
 * custom DropDownValueList working with backend value lists and custom given items
 * @param {object} props   valuelist, mode, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function DropDownValueList(props) {
  const [loaded, setLoaded] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { value, restrictCustomValue, allowClear, valuelist, mode, size, items, extraItems, parentValue, additionalData, reloadEventName, disableCach, clearMinutes, culture, autoSelectFirst, dispatch, searchOptions, searchServerSide, textAsValue, ...restprops } = props;
  const ref = useRef(false);
  const { getCacheData, setCacheData } = useSWCache({ key: `${culture}_${valuelist}_${parentValue}_${additionalData}`, isClearOnLogout: typeof clearMinutes === 'undefined', clearMinutes: disableCach ? 0 : clearMinutes });

  const manageValue = useCallback((data) => {

    if ((restrictCustomValue && !mode) && value && !data.some(item => item[textAsValue ? 'text' : 'value'] === value)) {
      props.onChange && props.onChange(autoSelectFirst ? data[0].value : '');
    } else if (autoSelectFirst && !value) {
      props.onChange && props.onChange(data[0].value);
    }

  }, [value, autoSelectFirst, textAsValue, restrictCustomValue, mode, props]);

  const loadData = useCallback(() => {
    if (valuelist) {
      if (typeof valuelist === 'string') {
        setLoaded(false);
        Ajax.post({
          url: ApiUrl.ValueList,
          data: {
            valueList: valuelist,
            parentValue: parentValue || "",
            additionalData: additionalData || ""
          },
          success: function (response) {
            if (response && ref.current) {
              const data = response;
              for (var i = 0; i < data.length; i++) {
                //data[i].date = new Date(data[i].date)
                data[i].key = data[i].uuid;
              };
              setDataSource(data);
              setLoaded(true);
              manageValue(data);
              !disableCach && setCacheData(data);
            }
          },
          error: function () {
            setDataSource([]);
            setLoaded(true);
          }
        });
      } else {
        setDataSource(valuelist);
        setLoaded(true);
        manageValue(valuelist);
      }
    }
  }, [valuelist, parentValue, additionalData, autoSelectFirst, disableCach, setCacheData, manageValue]);// eslint-disable-line react-hooks/exhaustive-deps

  useCustomEvent(reloadEventName || 'reload_valuelist_' + valuelist, loadData);

  useEffect(() => {
    ref.current = true;
    if (items) {
      setDataSource([...items]);
      setLoaded(true);
      manageValue(items);
    }
    else {
      if (disableCach) {
        loadData();
      } else {
        getCacheData().then((data) => {
          if (ref.current) {
            if (data) {
              setDataSource(data);
              setLoaded(true);
              manageValue(data);
            } else {
              loadData();
            }
          }
        });
      }
    }
    return () => { ref.current = false; };
  }, [additionalData, valuelist, parentValue]);// eslint-disable-line react-hooks/exhaustive-deps

  const filterOption = (input, option) => {
    let text = searchOptions.caseSensitive ? option.text : option.text && option.text.toLowerCase();
    let searchInput = searchOptions.caseSensitive ? input : input && input.toLowerCase();
    if (searchOptions.noAccent) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    return !searchInput || (text && (searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
  };

  const dataSourceOptions = dataSource.map((item, i) =>
    <Option key={i} value={textAsValue ? item.text : item.value} text={item.text}>
      {item.text}
    </Option>
  );
  return <>
    <Select
      allowClear={allowClear}
      size={size || "default"}
      mode={mode}
      virtual={false}
      value={value}
      {...restprops}
      onChange={(e) => props.onChange(e, dataSource)}
      {...(mode !== "tags" ? { filterOption: searchOptions && !searchServerSide && filterOption } : {})}
      showSearch={!!searchOptions || mode === "tags"}

    >
      {extraItems?.map((item, i) =>
        <Option key={i} value={textAsValue ? item.text : item.value} text={item.text}>
          {item.text}
        </Option>)
      }
      {(loaded || mode === 'tags') ? (extraItems && dataSource?.length ?
        <OptGroup label={<separator horizontal="" />}>
          {dataSourceOptions}
        </OptGroup> : dataSourceOptions) : <div style={{
          "margin": "auto",
          "background": "transparent"  }}><Spin size="small" /></div>}
    </Select>
  </>;
}

export default connect(state => ({ culture: state.culture }))(DropDownValueList);;