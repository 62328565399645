
import React, { useState } from 'react';
import { ApiUrl, UserRoles } from '../../project/Defines';
import { Switchable } from '../../Shared/Switchable';
import Schedule from './Schedule';

import PlanningBlock from './PlanningBlock';
import ListView from '../../Shared/ListView';
import { T } from '../../components/Translations';
import CallDetail from './CallDetail';
import { connect } from 'react-redux';
import { dispatchCustomEvent, EVENT_NAME, useCustomEvent } from '../../project/utilities';
import { useCallback } from 'react';

const listUpdateEvent = EVENT_NAME.PLANNING_BLOCK_LIST_UPDATE;

function Planning(props) {
    const { isSAT } = props;
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const onSwitchStateChanged = useCallback(() => {
        dispatchCustomEvent(EVENT_NAME.APPOINMENT_SCHEDULE_REPAINT);
    }, []);

    const onCallUpdated = useCallback((e) => {
        const { detail } = e;
        switch (detail && detail.type) {
            case 'accept':
                detail.data && setSelectedAppointment(detail.data);
                break;
            case 'reject':
                setSelectedAppointment(null);
                break;
            default:
                break;
        }
        dispatchCustomEvent(EVENT_NAME.APPOINMENT_SCHEDULE_UPDATE);
        dispatchCustomEvent(listUpdateEvent);
    }, []);

    useCustomEvent(EVENT_NAME.SERVICE_CALL_UPDATED, onCallUpdated);

    return <container>
        {isSAT && <Switchable middle="" left onShowStateChanged={onSwitchStateChanged}
            switchContent={
                <wrap><text><T>text.appointments</T></text></wrap>}>
            <ListView
                noPaging
                isListView
                apiUrl={ApiUrl.ServiceCallList}
                skipNewButton
                rowRender={(item) => <PlanningBlock onClick={() => setSelectedAppointment(item)} key={item.ID} data={item} listUpdateEvent={listUpdateEvent} />}
                listUpdateEvent={listUpdateEvent}
                defaultSorting={{ Member: "appointment_date_at", SortDirection: 0 }}
                staticFilter={{ unaccepted: true }}
            />
        </Switchable>}
        <Schedule setSelected={setSelectedAppointment} />
        <Switchable hide={false} name='text.service_call_details' left icon={<icon>collapse</icon>} onShowStateChanged={onSwitchStateChanged}>
            <CallDetail data={selectedAppointment} />
        </Switchable>
    </container>

}

export default connect(state => ({ isSAT: state.role === UserRoles.USER }))(Planning)