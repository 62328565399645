// import { useLocation } from 'react-router-dom';
// import { T } from '../../components/Translations';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PopupClasses, PopupTypes } from '../../components/Popup';
import { T } from '../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
// import Badge from '../../Shared/Badge';
import ListView from '../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../Shared/ListView/helper';
import { openPopup } from '../../store/actions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * @exports ServiceCalls
 */
function ServiceCalls(props) {
  const windowKey = 'wndSocietePicker';
  const { dispatch, unassigned, role, canCreateCall, culture } = props;
  const isDispatcher = role === UserRoles.DISPATCHER;
  const isCallCenter = role === UserRoles.CALL_CENTER;
  const { t } = useTranslation();

  // if (props.unassigned) {
  //     console.log('unassigned')
  // }
  // else {
  //     console.log('assigned')
  // }

  // debugger;

  const openPicker = useCallback(record => {
    dispatch(openPopup({
      windowKey,
      title: <T>text.assign_subcontractor</T>,
      type: PopupTypes.SocietePicker,
      bodyProps: {
        ...{ ID: record.ID, data: record, isConnectAs: false }
        // updateData: (record) => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, societe_uuid: record.uuid } }) }
      },
      className: PopupClasses.Large,
      fullScreen: false
    }));
  }, [dispatch]);


  const columns = [
    {
      text: 'text.call_id',
      // noSort: 'true',
      field: 'call_id',
      width: 80,
      fixed: 'left',
      type: COLUMN_TYPE.TEXT,
      linkAppPage: AppPages.ServiceCallsDetails
    },
    {
      text: 'text.subject',
      // noSort: 'true',
      field: 'subject',
      width: 300,
      fixed: 'left',
      render: (text, record) => {
        return (
          <wrap title={text}>
            <text light=''>{text}</text>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    // {
    //   text: 'text.call_center',
    //   // noSort: 'true',
    //   field: 'call_center',
    //   width: 120,
    //   fixed: 'left',
    //   type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.status',
      //field: 'status',
      field: 'call_status_translation',
      width: 170,
      fixed: 'left',
      render: (text, record) => {
        return (
          <wrap>
            <group compact="">
              <badge outline="">{text}</badge>
              {/* <badge outline="">{t('status.' + text).includes('status.') ? text : t('status.' + text)}</badge> */}
            </group>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.code_product',
      // noSort: 'true',
      field: 'code_product',
      width: 140,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.internal_sn',
      // noSort: 'true',
      field: 'internal_serial_number',
      width: 120,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.item_name',
      // noSort: 'true',
      field: 'item_name',
      width: 160,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.call_type',
      // noSort: 'true',
      field: 'call_type',
      width: 100,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.problem_type',
      // noSort: 'true',
      field: 'problem_type',
      width: 130,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.zip',
      // noSort: 'true',
      field: 'code_postal',
      width: 60,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.city',
      // noSort: 'true',
      field: 'ville',
      width: 110,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.phone',
      // noSort: 'true',
      field: 'phone',
      width: 120,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.created_date',
      // noSort: 'true',
      field: 'created_at',
      render: (text) => <wrap> <text>{moment.utc(text).local().format(Defines.Format.MomentDateTime)}</text></wrap>,
      width: 140,
      format: COLUMN_FORMAT.DATETIME,
      type: COLUMN_TYPE.TEXT
    },
    // {
    //     text: 'text.priority',
    //     noSort: 'true',
    //     field: 'priority',
    //     render: (text, record) => {
    //         return (
    //             <wrap>
    //                 <text>
    //                     <Badge priority={record.priority} />
    //                 </text>
    //             </wrap>
    //         );
    //     },
    //     type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.appointment_date',
      // noSort: 'true',
      field: 'appointment_date_at',
      width: 140,
      format: COLUMN_FORMAT.DATETIME,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.subcontractor',
      // noSort: 'true',
      field: 'subcontractor_name',
      width: 140,
      type: COLUMN_TYPE.TEXT
    },
    {
      type: COLUMN_TYPE.ACTION,
      fixed: 'right',
      commands: unassigned && [
        { command: 'edit' },
        { command: 'assign', icon: 'wrench', tooltip: "text.assign_manually", onClick: (record) => openPicker(record) }
      ]
    }
  ];

  //const exportData = () => {
  //    showLoader(loaderName);
  //    Ajax.download({
  //        url: ApiUrl.ServiceCallExport,
  //        data: {},
  //        //autoDownload: false,
  //        success: (blob) => {
  //            FileSaver.saveAs(blob, "ServiceCalls.xlsx");
  //            hideLoader(loaderName);
  //        },
  //        error: () => {
  //            hideLoader(loaderName);
  //        }
  //    });
  //};

  return (
    <>
      { //{<toolbar>
        //    <action right="">
        //        <div
        //            className='button primary'
        //            title="text.export"
        //            onClick={exportData}
        //        >
        //            <icon>download</icon><text>Export</text>
        //        </div>
        //    </action>
        //</toolbar>}
      }
      <ListView
        {...props}
        skipNewButton={(!(isDispatcher || isCallCenter) && !canCreateCall) || unassigned}
        className='scroll_fix'
        columnConfig={columns}
        apiUrl={ApiUrl.ServiceCallList}
        detailUrl={Project.getPageUrl(AppPages.ServiceCallsDetails)}
        filters={[
          {
            text: '', type: 'group', items: [
              { name: 'call_id', type: 'text', text: 'text.call_id' },
              { name: 'call_center', type: 'text', text: 'text.call_center' },
              { name: 'subject', type: 'text', text: 'text.subject' },
              { name: 'code', type: 'text', text: 'text.code_product' },
              { name: 'phone', type: 'text', text: 'text.telephone' },
              { name: 'customer_name', type: 'text', text: 'text.customer_name' },
              { name: 'adresse', type: 'text', text: 'text.street' },
              { name: 'code_postal', type: 'text', text: 'text.code_postal' },
              { name: 'internal_serial_number', type: 'text', text: 'text.internal_sn' },
              { name: 'priority', type: 'select', text: 'text.priority', valuelist: 'priority', additionalData: culture },
              // !unassigned && { name: 'status', type: 'select', text: 'text.status', valuelist: 'service_call_status', additionalData: culture, disableCach: true },
              !unassigned && { name: 'status', type: 'select', text: 'text.status', valuelist: 'call_status', disableCach: true },
              { name: 'call_type', type: 'select', text: 'text.call_type', valuelist: 'call_type' },
              { name: 'problem_type', type: 'select', text: 'text.problem_type', valuelist: 'problem_type' },
              { name: 'business_unit', type: 'select', text: 'text.tipo_producto', valuelist: 'tipo_producto' },
              !unassigned && {
                name: 'subContractors',
                type: 'select',
                text: 'text.subcontractor',
                valuelist: 'filter_subconstractors',
                mode: 'multiple',
                disableCach: true
              },
              !unassigned && isDispatcher && {
                name: 'dispatchers',
                type: 'select',
                text: 'text.dispatcher',
                valuelist: 'dispatchers',
                mode: 'multiple',
                disableCach: true
              }
            ]
          },
          {
            text: 'text.created_date', type: 'group', items: [
              { name: 'created_at', type: 'date', text: 'text.created_date' },
            ]
          },
          {
            text: 'text.appointment_date', type: 'group', items: [
              { name: 'appointment_date_at', type: 'date', text: 'text.appointment_date' }
            ]
          }
        ]}
        // staticFilter={{unassigned: true}}
        listUpdateEvent={EVENT_NAME.SERVICE_CALL_LIST_UPDATE}

        listName={AppPages.ServiceCalls}
        staticFilter={{ unassigned }}
        detailProps={{
          // fullScreen: false,
          popupType: PopupTypes.ServiceCallsDetails,
          windowKey: 'changeServiceCall',
        }}
        exportUrl={ApiUrl.ServiceCallExport}
      />
    </>
  );
}


export default connect(state => ({
  role: state.role,
  culture: state.culture,
  canCreateCall: state.userData.canCreateCall
}))(ServiceCalls);