import React, { useState } from 'react';
import { connect } from 'react-redux';
import { T } from '~/components/Translations';
import { ApiUrl } from '~/project/Defines.js';
import { EVENT_NAME } from '~/project/utilities.js';
import ListView from '~/Shared/ListView';
import { COLUMN_TYPE } from '~/Shared/ListView/helper';
import { PopupTypes } from '../../../components/Popup';
import { openPopup } from '../../../store/actions';




/**
 * the societe List
 * @exports EquipmentCardPicker
 */
function EquipmentCardPicker(props) {
    const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
    const { internal_serial_number, onClose, setDataItem } = props;
    const [detailProps] = useState({
        windowKey: 'wndEquipmentCardCreate',
        popupType: PopupTypes.EquipmentCardEdit,
        fullScreen: false,
        listUpdateEvent,
        internal_serial_number
    });
    //const openEquipmentCardCreate = useCallback(() => {
    //    openPopup({
    //        windowKey: 'wndEquipmentCardCreate',
    //        title: <T>text.new_equipment_card</T>,
    //        type: PopupTypes.EquipmentCardCreate,
    //        className: PopupClasses.Large,
    //        fullScreen: false,
    //        bodyProps: { internal_serial_number, listUpdateEvent },
    //    });
    //}, [internal_serial_number, listUpdateEvent, openPopup]);
    const onLoaded = (data) => {
        return data.map(card => {
            const index = card.OwnerName.indexOf('|');
            card.OwnerCode = card.OwnerName.slice(0, index);
            if (index !== -1) {
                card.OwnerName = card.OwnerName.slice(index + 1);
            }
            return card;
        });
    };

    const onSelect = (record) => {
        console.log(record);
        setDataItem(item => ({ ...item, product_code: record.CodeCom, item_code: record.ItemCode, internal_serial_number: record.InternalSN, customer_code: record.OwnerCode }));
        onClose();
    };
    const columnConfig = [
        {
            text: 'text.serial_number',
            field: 'InternalSN',
            type: COLUMN_TYPE.TEXT,
            noSort: true
        },
        {
            text: 'text.product',
            field: 'CodeCom',
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            render: (t, record) => {
                const text = [
                    record.CodeCom,
                    record.ItemName
                ].filter(x => !!x).join(' - ');
                return <wrap title={text} >
                    <text>{text}</text>
                </wrap>;
            }
        },
        {
            text: 'text.customer_name',
            field: 'OwnerName',
            type: COLUMN_TYPE.TEXT,
            noSort: true
        },
        {
            text: 'text.address',
            field: 'Street',
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            render: (t, record) => {
                const text = [
                    record.Street,
                    record.City,
                    record.Zip,
                    record.Country
                ].filter(x => !!x).join(', ');
                return <wrap title={text} >
                    <text>{text}</text>
                </wrap>;
            }
        },
        {
            type: COLUMN_TYPE.CUSTOM,
            width: 100,

            render: (text, record) => <elastic>
                <action hover="">
                    <div
                        effect="material"
                        className="button accent"
                        onClick={() => onSelect(record)}
                    >
                        <text><T>text.select</T></text>
                    </div>
                </action>
            </elastic>
        },
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    return (
        <>
            <ListView
                {...props}
                isSimple
                columnConfig={columnConfig}
                apiUrl={ApiUrl.EquipmentCardList}
                staticFilter={{ internal_serial_number }}
                keyField='InsId'
                // onNewClick={openEquipmentCardCreate}
                listUpdateEvent={listUpdateEvent}
                detailProps={detailProps}
                onLoaded={onLoaded}
            />
        </>
    );
}

export default connect(null, dispatch => ({ openPopup: (props) => dispatch(openPopup(props)) }))(EquipmentCardPicker);