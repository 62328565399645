import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Ajax } from '../../../components/Ajax';
import { PopupClasses, PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import DetailView from '../../../Shared/DetailView';
import InputCont from '../../../Shared/Form/InputCont';
import { hideLoader, showLoader } from '../../../Shared/Loader';
import useDetail from '../../../Shared/useDetail';
import { closeAllPopups, openPopup } from '../../../store/actions';
import Attachments from '../Attachment';

import moment from 'moment';

const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
/**
 * @exports ServiceCallsDetails
 */
function CreateCall(props) {
  const [validationFields] = useState([]);//['product_code', 'internal_serial_number']);
  const [attachments, setAttchments] = useState([]);
  const [currentDate] = useState(moment(new Date(), Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime));
  const { windowKey, openPopup, closeAllPopups, record, isDuplicate, isSat, culture } = props;
  const history = useHistory();

  const useDetailResult = useDetail({
    data: { ID: record?.ID || null },
    updateUrl: ApiUrl.CreateServiceCall,
    windowKey,
    dataLoadUrl: ApiUrl.ServiceCallLoad,
    nameField: 'code',
    backPage: AppPages.ServiceCalls,
    listUpdateEvent,
    validationFields,
    changingValidation: true,
    onLoadData: (response, setDataItem) => {
      setDataItem({ ...response, wish_date_from: currentDate });
      return true;
    },
    // detailUrl: Project.getPageUrl(AppPages.ServiceCallsDetails)
  });

  const { dataItem, setDataItem, updateData, onChangeData, validation, detailRef } = useDetailResult;
  const _serial_number = props?.location?.state?._data?.internal_serial_number
  console.log(props, dataItem);

  useEffect(() => {
    if(_serial_number) {
      setDataItem({ ...dataItem, internal_serial_number: _serial_number });
    }
    if (isDuplicate && dataItem) {
      setDataItem({ ...dataItem, product_code: dataItem.code_product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDataItem, dataItem?.ID]);

  const updateSuccessCallback = (response) => {
    if (detailRef.current) {
      // debugger;
      if (isSat || response.societe_uuid) {
        updateCallback(response);
      } else {
        openPopup({
          type: PopupTypes.Confirm,
          title: <T>text.assign_subcontractor</T>,
          text: <T> message.assign_manually</T>,
          description: <T> message.assign_manually_description</T>,
          buttonYesText: <T>text.assign_manually</T>,
          buttonNoText: <T>text.assign_with_algorithm</T>,
          ...(!response?.hide_assign_to_previous_button && {
            buttons: [{
              text: <T>text.assign_to_the_previous_SAT</T>, callback: (onClose) => {
                autoAssign(response, 'assignToPreviousSat');
                onClose();
              }
            }]
          }),
          yesCallback: function () { openPicker(response); },
          noCallback: function () { autoAssign(response, 'assign'); }
        });
      }

    }

  };

  const autoAssign = (response, updateType) => {
    showLoader('assign_auto');
    Ajax.post({
      url: ApiUrl.ServiceCallUpdate,
      data: { id: response.ID, update_type: updateType },
      success: function (result) {
        updateCallback(result);
        hideLoader('assign_auto');

      }
    });
  };

  const updateCallback = (data) => {
    closeAllPopups();
    history.push(Project.getPageUrl(AppPages.ServiceCallsDetails, data.ID));
    window.location.reload();
  };

  const openPicker = record => {
    openPopup({
      windowKey,
      title: <T>text.assign_subcontractor</T>,
      type: PopupTypes.SocietePicker,
      bodyProps: {
        ...{ ID: record.ID, data: record, updateCallback }
        // updateData: (record) => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, societe_uuid: record.uuid } }) }
      },
      className: PopupClasses.Large,
      fullScreen: false
    });
  };

  const internal_serial_number = dataItem && dataItem.internal_serial_number;
  const openEquipmentCards = useCallback(() => {
    openPopup({
      windowKey: 'wndEquipmentCard',
      title: <T>text.pick_equipment_card</T>,
      type: PopupTypes.EquipmentCardPicker,
      className: PopupClasses.Large,
      fullScreen: false,
      bodyProps: { internal_serial_number, setDataItem },
    });
  }, [internal_serial_number, setDataItem, openPopup]);

  let serviceCallData = dataItem && <>
    <div className="form_container">
      <view auto_height=''>
        <div className='section_group'>
          <div className='sections'>
            <InputCont
              label='text.call_type'
              inputProps={{
                type: 'select',
                valuelist: 'call_type',
                name: 'call_type_id',
                value: dataItem.call_type_id,
                disableCach: true,
                placeholder: "",
                onChange: (e) => onChangeData({ target: { name: 'call_type_id', value: e } })
              }}
            />
          </div>
          <div className='sections'>
            <InputCont
              label='text.start_date'
              inputProps={{
                validation,
                allowClear: false,
                type: 'datetime',
                name: 'wish_date_from',
                dataItem: dataItem,
                setDataItem,
                dataField: 'wish_date_from'
              }}
            />
          </div>
          <div className='sections '>
            <InputCont
              label='text.serial_number'
              inputProps={{
                name: 'internal_serial_number',
                value: dataItem.internal_serial_number,
                maxLength: 250,
                onChange: onChangeData,
                validation
              }}
              icon={<div effect="material" className="button mini " disabled={!dataItem.internal_serial_number} onClick={openEquipmentCards}><icon >search</icon></div>}

            />
          </div>
          <div className='sections '>
            <InputCont
              label='text.product_code'
              inputProps={{
                name: 'product_code',
                value: dataItem.product_code,
                maxLength: 250,
                onChange: onChangeData,
                validation,
                disabled: true
              }}
            />
          </div>
        </div>
        <div className='section_group'>
          <div className='sections'>
            <InputCont
              label='text.problem_type'
              inputProps={{
                type: 'select',
                valuelist: '_problem_type',
                name: 'problem_type_id',
                value: dataItem.problem_type_id,
                parentValue: dataItem.call_type_id,
                disabled: dataItem.call_type_id === "",
                disableCach: true,
                placeholder: "",
                onChange: (e) => onChangeData({ target: { name: 'problem_type_id', value: e } })
              }}
            />
          </div>
          <div className='sections'>
            <InputCont
              label='text.priority'
              inputProps={{
                type: 'select',
                valuelist: 'priority',
                name: 'priority',
                value: dataItem.priority,
                additionalData: culture,
                placeholder: "",
                onChange: (e) => onChangeData({ target: { name: 'priority', value: e } })
              }}
            />
          </div>
        </div>
        <div className='section_group'>
          <div className='sections wide'>
            <InputCont
              label='text.subject'
              inputProps={{
                name: 'subject',
                value: dataItem.subject,
                placeholder: "",
                maxLength: 250,
                onChange: onChangeData
              }}
            />
            <InputCont
              label='text.description'
              inputProps={{
                type: 'textarea',
                name: 'description',
                value: dataItem.description,
                maxLength: 2000,
                placeholder: "",
                onChange: onChangeData
              }}
            />
          </div>
        </div>
        <div className='section_group' style={{ padding: '20px' }}>
          <Attachments dataSource={attachments} setDataSource={setAttchments} />
        </div>
      </view>
    </div>
    <toolbar>
      <wrap>
        <action right=''>
          <div onClick={(e) => updateData(updateSuccessCallback, null, { additionalData: { attachments } })} className='button'><icon>save</icon><T>text.save</T></div>
        </action>
      </wrap>
    </toolbar>
  </>;

  return (<>
    {isDuplicate ?
      serviceCallData
      : <DetailView
        backUrl={Project.getPageUrl(AppPages.ServiceCalls)}
        title={<T>text.create_new_call</T>}
        sare>
        {serviceCallData}
      </DetailView>
    }
  </>);
}

export default connect(state => ({
  isDispatcher: state.role === UserRoles.DISPATCHER,
  isSat: state.role === UserRoles.USER,
  culture: state.culture
}), dispatch => ({ dispatch, openPopup: (props) => dispatch(openPopup(props)), closeAllPopups: () => dispatch(closeAllPopups()) }))(CreateCall);