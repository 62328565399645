import React from 'react';
import { Defines } from '../../../project/Defines';
import InputCont from '../../../Shared/Form/InputCont';

export default function Product(props) {
    const { dataItem, disabled, onChangeData, setDataItem } = props;

    return (
        <div className='section_group'>
            <div className='sections '>
                <form>

                    <InputCont
                        label='text.serial_number'
                        inputProps={{
                            disabled,
                            name: 'internal_serial_number',
                            value: dataItem.internal_serial_number,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                    <InputCont
                        label='text.product_code'
                        inputProps={{
                            disabled: true,
                            name: 'code_product',
                            value: dataItem.code_product,
                            placeholder: ""
                        }}
                    />
                    <InputCont
                        label='text.product_name'
                        inputProps={{
                            disabled: true,
                            name: 'item_name',
                            value: dataItem.item_name,
                            placeholder: ""
                        }}
                    />
                </form>

            </div>
            <div className='sections'>
                <form>

                    <InputCont
                        label='text.warranty_start_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_start_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_start_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "", setDataItem
                        }}
                    />
                    <InputCont
                        label='text.warranty_end_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_end_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_end_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "", setDataItem
                        }}
                    />
                    <InputCont
                        label='text.warranty_extension_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_extension_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_extension_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "",
                            setDataItem
                        }}
                    />
                </form>
            </div>
        </div>
    );
}