
import React, { useCallback } from 'react';
import { T } from '../../components/Translations';
import { ApiUrl } from '../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import InputCont from '../../Shared/Form/InputCont';
import useDetail from '../../Shared/useDetail';


const RejectDocument = (props) => {
    const { record, onClose, listUpdateEvent, type, docType } = props;
    const onLoadData = useCallback((response, setDataItem) => {
        setDataItem({ ...record, callID: record.callData.ID, type, docType, email: response, comment: '' });
        return true;
    }, [record, type, docType]);

    const { dataItem, updateData, onChangeData, validation } = useDetail({
        data: { ...record, callID: record.callData.ID, type, docType },
        updateUrl: ApiUrl.RejectDocument,
        dataLoadUrl: ApiUrl.GetSatEmail,
        dataLoadParams: { service_call_id: record.callData.ID },
        validationFields: [{ name: 'email', rules: [{ type: "required" }, { type: "email" }] }],
        onLoadData
    });
    const getSubject = useCallback(() => {
        switch (type) {
            case "PO":
                return `${record.callData.call_id} Mano de obra rechazada `;
            case "RR":
                if (docType === "DPC") {
                    return `${record.callData.call_id} DPC rechazada  `;
                } else if (docType === "SP") {
                    return `${record.callData.call_id} Solicitud de devolucion rechazada`;
                }
                break;
            default:
                return "";
        }
    }, [type, docType, record]);
    return dataItem &&
        <> <toolbar transparent="">
            <text code=""
                data-size="large"
                data_weight="500">{record.DocNum}</text>
        </toolbar>
            <div className='form_container'>
                <div className='section_group'>
                    <div className='sections'>
                        <form>
                            <InputCont
                                label="text.email"
                                inputProps={{ name: 'email', validation, value: dataItem.email, onChange: onChangeData }} />
                            <InputCont
                                label="text.subject"
                                inputProps={{ name: 'subject', disabled: true, value: getSubject(), onChange: onChangeData }} />
                            <InputCont
                                label="text.observations"
                                inputProps={{ name: 'comment', validation, type: 'textarea', value: dataItem.comment, onChange: onChangeData }} />

                        </form>
                    </div>
                </div>
            </div>
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={(e) => updateData((response) => {
                            dispatchCustomEvent(listUpdateEvent);
                            dispatchCustomEvent([EVENT_NAME.SERVICE_CALL_UPDATED, EVENT_NAME.FEE_LIST_UPDATE, EVENT_NAME.SPARE_PART_LIST_UPDATE, EVENT_NAME.DPC_LIST_UPDATE].join(" "));
                            onClose();
                        })} className='button'><icon>mail</icon><T>text.reject</T></div>
                        <div onClick={onClose} className='button'><icon>close</icon><T>text.cancel</T></div>
                    </action>
                </wrap>
            </toolbar>
        </>;

};


export default RejectDocument;

